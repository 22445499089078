'use strict';

// Check if object exists
function ifExists(object) {
  return object.length > 0;
}

$(function() {

  // Mobile toggle
  $('.navigation-toggle__link').click(function(e) {
    e.preventDefault();

    $(this).toggleClass('is-active');
    $(this).find('.navigation-toggle__link__icon').toggleClass('open');
    $('.navigation-container').slideToggle();
  });

});
